@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/mixins/col.scss";

.grid-header {
  position: relative;
  cursor: pointer;
  background-color: $blue-nuvelar;
  color: $white;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;

  padding: 8px 10px;    

  &:before {
    right: 0;
    content: "\2193";
    position: absolute;
    display: block;
    width: 10px;
    top: 6px;
    cursor: pointer;
    opacity: 0.3;
  }
  &:after {
    right: 0.5rem;
    content: "\2191";
    position: absolute;
    display: block;
    width: 10px;
    top: 6px;
    cursor: pointer;
    opacity: 0.3;
  }

  &.ordered-asc {
    &:after {
      opacity: 1;
    }
  }

  &.ordered-desc {
    &:before {
      opacity: 1;
    }
  }
}