$blue-nuvelar: #3bb6e5;
$green-nuvelar: #80cc14;
$red-nuvelar: #e27777;

$dark-green-nuvelar: #4e7e0b;

$black: #000;
$white: #fff;
$grey: #efefef;
$dark-grey: #ccc;
