@import '../../assets/scss/colors.scss';

.btn {
  text-transform: none;
}

.btn-default {

  &.btn-nuv {
    background-color: $dark-grey !important;
    color: $white;

    &:hover {
      background-color: $grey !important;
    }
  }
}

.btn-disabled {
  pointer-events: none;
  opacity: 0.7;
}


.btn-small {
  padding: 2px 12px;
  font-size: 13px;
}