@import '../../assets/scss/colors.scss';

.Header {
  .navbar-brand {
    height:50px;
  }

  header {
    display: flex;
    align-items: center;
    height: 60px;
    justify-content: space-between;
  }

  .filters {
    display: flex;

    .filter-select {
      display: flex;
      flex-direction: column;
      margin-right: 30px;
      min-width: 180px;
    }

    input {
      width: 150px;
      height: 30px;
      padding: 5px;
      border-radius: 5px;
      background: none;
      border: 1px solid $dark-grey;
    }
  }

  .suscriptions-data {
    margin-right: 15px;
    font-weight: 600;
    font-size: 0.95rem;

    &.invalid {
      color: red;
    }
  }
}
