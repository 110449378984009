.item-actions {
  display: flex;
  justify-content: center;

  .item-action {
    cursor: pointer;
    // padding: 5px;
    color: #fff;
    border-radius: 3px;
    width: 30px;
    height: 30px;
    text-align: center;
    margin-right: 5px;
  }
}

.rating-cell {
  font-size: 0.7rem;
  min-width: 125px;
}