@import "../../assets/scss/colors.scss";

.edit-user {
  padding: 10px;
  position: relative;
  padding-top: 75px;
  height: calc(100% - 40px);

  .edit-user-header {
    position: absolute;
    top: 0px;
    width: calc(100% - 30px);
    padding: 10px;
    padding-left: 20px;

    h3 {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.5rem;
    }

    .edit-user-options {
      display: flex;
      width: 60px;
      justify-content: space-between;

      .icon {
        background-image: url(../../assets/img/ico_close.svg);
        width: 25px;
        height: 25px;
        filter: brightness(0) sepia(0) hue-rotate(0deg) saturate(0);
        cursor: pointer;

        &:hover {
          filter: brightness(0.5) sepia(1) hue-rotate(140deg) saturate(6);
        }
      }

      .close-icon {
        background-image: url(../../assets/img/ico_close.svg);
      }
      .open-icon {
        background-image: url(../../assets/img/ico_open.svg);
      }
    }

    
  }

  hr {
    width: 80%;
    margin: 25px;
    margin-top: 30px;
  }

  .panel {
    padding-left: 20px;

    h4 {
      margin-top: 0px;
    }

    .panel-body {
      label {
        display: block;
        font-size: 0.8rem;
        padding-left: 8px;
      }

      input {
        background: none;
        border-radius: 8px;
        padding: 8px;
        margin-bottom: 10px;
        margin-top: 2px;
        width: 80%;
        border: 1px solid $dark-grey;
      }

      .panel-option {
        .dropdown {
          margin: 10px;
          width: 35%;

          label {
            padding-left: 0px;
          }
        }
      }
    }
  }
}
