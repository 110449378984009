#tabla_casos_length {
  label {
    display: flex;
    align-items: center;

    select {
      width: 80px;
      margin: 0 15px;
    }
  }
}

#tabla_casos_filter {
  display: flex;
  justify-content: flex-end;

  label {
    display: flex;
    align-items: center;

    input {
      margin-left: 10px;
    }
  }
}
