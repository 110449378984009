@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800&amp;subset=latin-ext");

body {
  font-family: "Open Sans", Arial;
  margin: 0;
  background-color: #fff;
}

section {
  height: calc(100vh - 105px);
  padding: 20px 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.language-switcher {
  width: auto;
  margin-left: 20px;
  margin-top: 15px;
}

input:disabled {
  opacity: 0.7;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  font-weight: 600;
}

.align-end {
  align-self: flex-end;
}