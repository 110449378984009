@import "../../assets/scss/colors.scss";

.users-container {
  display: flex;

  .users-list {
    flex-grow: 1;
    font-size: 0.8rem;
    padding: 0 15px;
    display: flex;
    flex-direction: column;

    .users-list-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .user-selected {
      font-weight: bold;
    }

    .status {
      display: flex;
      width: 15px;
      height: 15px;
      border-radius: 50%;

      &.active {
        background-color: #8ee607;
      }

      &.inactive {
        background-color: red;
      }
    }
  }

  .edit-user-container {
    position: absolute;
    right: 0px;
    width: 45%;
    background: $white;
    z-index: 5;
  }
}