@import '../../assets/scss/colors.scss';

.dropdown {
  position: relative;
  font-size: 0.9rem;
  cursor: pointer;

  &.dropdown-disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  label {
    font-size: 0.8rem;
    display: block;
    text-transform: uppercase;
  }

  span {
    font-weight: bold;
    position: relative;
    padding-right: 18px;
    display: block;

    &:after {
      content: '';
      position: absolute;
      height: 15px;
      width: 15px;
      right: 0;
      top: 0;
      background-image: url(../../assets/img/ico_dropdown.png);
    }
  }

  .dropdown-options-container {
    display: none;
    position: absolute;
    width: 100%;
    margin-top: -7px;
    z-index: 99;

    .dropdown-options {
      background: $white;
      border: 1px solid $grey;
      padding: 8px;
      border-radius: 8px;
      margin-top: 10px;
      margin-left: -5px;
      line-height: 1.4rem;

      p {
        margin: 0px;
        padding: 2px 12px;
        border-radius: 5px;
      }

      p:hover {
        background-color: $grey;
      }
    }
  }

  &.dropdown-visible {
    .dropdown-options-container {
      display: block;
    }
  }
}