#grid {
    width: 100%;

    td {
        padding: 0.55rem;
        max-width: 120px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}