@import '../../assets/scss/colors.scss';

section.login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  .language-login {
    position: fixed;
    top: 10px;
    right: 10px;
    background: $white;
    padding: 10px;
    border-radius: 5px;
  }

  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white;
    width: 300px;
    padding: 25px 15px;
    border-radius: 8px;

    .form-element {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      &:last-of-type {
        margin-top: 5px;
        margin-bottom: 0px;
      }

      label {
        font-weight: 600;
        font-size: 0.9rem;
      }

      input {
        border-radius: 8px;
        border: 1px solid $dark-grey;
        padding: 10px 5px;
      }
    }
  }
}